.divider_line {
  width: 2px;
  height: 12px;
  background-color: #ffffff4d;
}

#mobile-slider-scroller::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.pt_24 {
  padding-top: 24px;
}

.pb_65 {
  padding-bottom: 65px;
}

.rotate_divide_line {
  transform: rotate(9deg);
}

.paragraph_future_max_w {
  max-width: 680px;
}

.logo_spacing {
  padding-left: 40px;
  padding-top: 40px;
}

ol,
ul {
  padding-left: 0px !important;
}

.icons_gap {
  gap: 32px;
}

.social_links {
  transition: all 400ms linear;
}

.social_links:hover {
  scale: 1.2;
}

.common_box {
  width: calc(50% - 142px);
  padding: 10px;
  border: 1px solid #3a3a3a;
  z-index: 10;
}

.common_text_transform {
  transform: rotate(270deg);
}

.gap_40 {
  gap: 40px;
}

.common_box_position {
  position: absolute;
  right: 20px;
}

.card_mobile_spacing {
  margin: 0px 20px;
}

.responsive_box {
  width: 100% !important;
  padding: 12px;
}
.responsive_box .ratio-box {
  aspect-ratio: 311 / 161;
}

.responsive_box_pos {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.social_sensor_img_w {
  max-width: 284px;
  height: 161px;
  width: 100%;
}

.sensor_second_img {
  height: 160px;
}

.cross_icon_box {
  width: 32px;
  height: 32px;
  background: var(--color-grey);
}

.social_tensor_transition_2 {
  transition: all ease 500ms, width 0ms ease !important;
}

.gap_12 {
  gap: 12px;
}

.gap_20 {
  gap: 20px;
}

.branding_text_transform {
  transform: rotate(270deg);
  position: absolute;
  left: -19px;
  bottom: 20px;
}

.ui_ux_text_transform {
  transform: rotate(270deg);
  position: absolute;
  left: -7px;
  bottom: 8px;
}

.branding_text_pos {
  position: absolute;
  left: -11px;
  bottom: 2px;
}

.cursor_pointer {
  cursor: pointer;
}

.video_width {
  max-width: 396px;
}

/* SLIDER CSS CODE HERE */
.scroll_height {
  height: 100%;
  overflow-y: scroll;
}

.hovered_position {
  top: 20px !important;
  right: 20px !important;
}

.card_hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.5s, opacity 0.5s;
}

.card_visible {
  visibility: visible;
  opacity: 1;
  transition: visibility 0.5s, opacity 0.5s;
}

.opacity_20 {
  opacity: 20%;
}

.slider_text {
  transition: all 500ms linear;
}

.slider_text:hover,
.hover_opacity_1:hover {
  opacity: 1 !important;
}

.slider_container {
  width: 100%;
  position: relative;
  display: flex;
  overflow: hidden;
}

.slider_parent,
.slider_reverse {
  display: flex;
  width: auto;
  animation-duration: 110s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.slider_parent {
  animation-name: slide;
}

.slider_reverse {
  animation-name: slide_rev;
}

/* Combined hover effects */
.slider_p_hover:hover * {
  animation-play-state: paused;
}

.slider_item {
  flex-shrink: 0;
  box-sizing: border-box;
  font-size: 32px;
  padding-left: 12px;
  padding-right: 12px;
  text-align: center;
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

@keyframes slide_rev {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0%);
  }
}

@media (min-width: 576px) {
  .icons_gap {
    gap: 19px;
  }

  .branding_padding {
    padding: 40px 0px 0px 40px;
  }

  .nav_link::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -3px;
    border-radius: 10px;
    left: 0;
    background-color: white;
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out;
  }

  .nav_link:hover::before {
    visibility: visible;
    transform: scaleX(1);
  }
}

@media (max-width: 768px) {
  .social_sensor_img_w {
    width: calc(100% - 27px);
    height: 100%;
    max-width: none;
  }
}

@media (min-width: 768px) {
  .common_box {
    height: 246px;
  }

  .icons_gap {
    gap: 40px;
  }

  .responsive_box {
    width: 335px !important;
    padding: 12px;
  }

  .responsive_box_pos {
    width: auto;
  }
}

.expand_single_card {
  width: 78.5% !important;
}

@media (min-width: 1200px) {
  .common_box {
    height: 246px;
  }

  .common_box_position {
    top: -100%;
  }

  .social_sensor_img_w {
    max-width: 387.75px;
    width: 100%;
    height: 226px !important;
    object-fit: contain;
  }

  .common_box_position_transform_1 {
    transform: translate(-104%, 0%);
    right: 40px !important;
    transition: top ease-in-out 900ms !important;
  }
}

@media (min-width: 1400px) {
  .expand_single_card {
    width: 81.5% !important;
  }

  .social_sensor_img_w {
    max-width: 416.75px;
    object-fit: contain;
  }
}

@media (max-width: 575.98px) {
  .video_width {
    max-width: 302px;
  }

  .header_custom_spacing {
    padding-bottom: 17px;
  }

  .menu_bar {
    cursor: pointer;
  }

  .common_padding {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .cross span {
    background: white;
  }

  .cross span:nth-child(1) {
    transform: rotate(45deg) translate(7px, 9px);
    margin-bottom: 9px;
  }

  .cross span:nth-child(2) {
    transform: rotate(-45deg) translate(-2px);
    margin-bottom: 9px;
  }

  .z_9 {
    z-index: 9 !important;
  }

  .menu_bar span {
    display: block;
    width: 22px;
    height: 2px;
    background: white;
    transition: all 0.3s;
  }

  .navbar_nav {
    background-color: black;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: start;
    justify-content: start;
    flex-direction: column;
    position: fixed;
    right: -100%;
    top: 0;
    padding-top: 40px;
    visibility: hidden;
    opacity: 0;
    transition: 0.3s ease-in;
  }

  .overflow_hidden {
    overflow: hidden;
  }

  .navbar_nav.show {
    visibility: visible;
    opacity: 1;
    right: 0;
  }
}

.trx_full {
  transform: translate(-100%, 0%) !important;
}

.trx_0 {
  transform: translate(0, 0) !important;
}
