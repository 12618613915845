* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style-type: none;
  text-decoration: none !important;
  font-family: "Gilroy_SemiBoldItalic";
}
.vh_100 {
  height: 100dvh !important;
}
html,
body {
  overflow: hidden;
}
@font-face {
  font-family: Acumin_Pro_Wide;
  src: url("./assets/fonts/Acumin_Pro_Wide.otf");
  font-display: swap;
}

@font-face {
  font-family: Acumin_Pro_condensed;
  src: url("./assets/fonts/Acumin_Pro_Condensed.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Gilroy_SemiBoldItalic";
  src: url("./assets/fonts/gilroy_extra_bold.ttf");
}

.font_acumin_pro_wide {
  font-family: "Acumin_Pro_Wide";
}

.font_acumin_pro_condensed {
  font-family: "Acumin_Pro_condensed";
}

:root {
  --font-sm: 12px;
  --font-md: 16px;
  --font-lg: 24px;
  --font-xl: 32px;
  --font-2xl: 46px;
  /* ----color- */
  --color-grey: #252525;
}

.grey {
  background-color: var(--color-grey);
}

.lts_1 {
  letter-spacing: 1px;
}

.lh_12 {
  line-height: 12px;
}

.lh_16 {
  line-height: 16px;
}

.lh_38 {
  line-height: 28.8px;
}

.lh_14 {
  line-height: 14.4px;
}

.lh_15 {
  line-height: 15.6px;
}

.text_sm {
  font-size: var(--font-sm);
}

.text_13 {
  font-size: 13px;
}

.text_md {
  font-size: var(--font-md);
}

.text_lg {
  font-size: var(--font-lg);
}

.text_xl {
  font-size: var(--font-xl);
}

.text_2xl {
  font-size: var(--font-2xl);
}

@media (min-width: 1025px) {
  :root {
    --font-xl: 42px;
    --font-2xl: 80px;
  }
}

@media (min-width: 576px) {
  :root {
    --font-sm: 12px;
    --font-md: 12px;
    --font-lg: 32px;
  }

  .lh_38 {
    line-height: 38.4px;
  }
}